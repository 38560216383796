<template>
  <div class="meta-selector-option" :class="{'meta-selector-option--selected':isSelected}" @click="handleClicked">
    <slot></slot>
  </div>
</template>

<script>
import {colorLog} from "../../util";

export default {
  name: "MetaSelectorOption",
  props:{
    label:{
      type:String,
    },
    value:{
      required:true
    }
  },
  data(){
    return {
      temp_label:this.label || this.value,
      temp_value:this.value,
      isSelected:false,
      optionId:''
    }
  },
  watch:{
    '$parent.selectedValue':function (newVal){
      if(newVal == this.value){
        this.select();
      }else{
        this.cancelSelected();
      }
    }
  },
  created() {
    this.$parent.setOption(this.temp_value,this.temp_label);

  },
  methods:{
    handleClicked(e){
      let res = {label:this.temp_label,value:this.temp_value,optionId:this.optionId};

      this.$parent.optionClickedListener(res,this);
      return res;
    },
    select(){
      this.isSelected = true;
    },
    cancelSelected(){
      this.isSelected = false;
    }
  },
  mounted() {
    this.optionId = this.$el.closest('[data-meta-option-id]').getAttribute('data-meta-option-id');
    let res = {label:this.temp_label,value:this.temp_value,optionId:this.optionId.slice(-1)};
    this.$parent.optionMountedListener(res);

    this.$parent.registerOptionComponent(this.optionId,this);

    if(this.$parent.$props.value == this.temp_value){
      this.isSelected = true;
      this.$parent.select(this.$parent.value);
    }
  },
  beforeUnmount() {
    this.$parent.deleteOption(this.temp_value);
    this.$parent.offOptionComponent(this.optionId);
  },


}
</script>

<style scoped>
.meta-selector-option{
  --option-darkBlue:#3B5DBBFF;
  --option-darkBlue:#5280FFFF;
  cursor: pointer;
}
.meta-selector-option--selected{
  color: var(--lightBlue,var(--option-darkBlue));
  font-weight: bold;
}
</style>
