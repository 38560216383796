<template>
  <div class="meta-level-selector" ref="selector">
    <div class="meta-level-selector-bar meta-level-selector--variation"
         ref="selectorBar"
         :class="{'meta-level-selector-bar--active':showDropDown}"
         @click="handleSelectorClicked">
      <slot name="leftPart" :data="$data"></slot>
      <div class="selector-bulletin"
           :class="{
            'selector-bulletin-textOverflow--ellipse': textOverflow == 'ellipse',
            'selector-bulletin-textOverflow--placeholder':inputText ? false : true
           }"
           :title="inputText"
      >{{inputText ? inputText : placeholder}}</div>
      <slot name="rightPart"></slot>
      <svg t="1645423194155" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2058" width="12" height="12"><path d="M326.9 67l446.6 447.7-446.6 447.5-76.2-76.3 370.5-371.2-370.5-371.4L326.9 67z m0 0" p-id="2059"></path></svg>
      <!--    <input v-model="inputText">-->
    </div>
    <div class="meta-level-dropDown"
         :class="{
      'meta-level-dropDown--hidden':!showDropDown,
      'meta-level-dropDown--resetting':isResetting
    }"
         :style="{
          '--meta-level-dropDown-left':dropDownContainerLeft + 'px',
          '--meta-level-dropDown-top':Math.floor(dropDownContainerTop) + 'px'
         }"
         @transitionend="handleTransitionEnd(showDropDown,$event)"
         ref="dropDown">
      <div class="mld-options"
           :class="{
           'mld-options-beginning':leaf.level == '0'
           }"
           :style="{'margin-top':leaf.offsetY+'px'}"
           :key="leaf.level"
           v-for="(leaf,index) in processedRoot">
        <!--    分组    -->
        <template
            v-if="classifyPropName"
        >
          <div class="mld-option-group" v-for="(group,groupIndex) in leaf.data">
            <p class="mld-option-group-name" v-if="group.groupName">{{group.groupName}}</p>
            <div class="mld-option-item"
                 :data-meta-option-id="concatLevel(leaf.level,child._index).join('.')"
                 @click="handleOptionClicked(child,leaf.level,child._index)"
                 :key="index2"
                 v-for="(child,index2) in group.children">
              <slot name="child" :leaf="leaf" :child="child" :index="child._index"></slot>
              <svg
                  class="icon mld-option-item-expandBtn"
                  :class="{'mld-option-item-expandBtn--active':
              root.find(item => levelsEqual(item.level,concatLevel(leaf.level,child._index)) ),
              'hidden':!child[childPropName] || !child[childPropName].length
        }"
                  @click.capture.stop="toggleRoot(child,concatLevel(leaf.level,child._index))"
                  t="1645423194155" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2058" width="12" height="12"><path d="M326.9 67l446.6 447.7-446.6 447.5-76.2-76.3 370.5-371.2-370.5-371.4L326.9 67z m0 0" p-id="2059"></path></svg>
            </div>
          </div>
        </template>

        <!--    普通    -->
        <template v-else>
          <div class="mld-option-item"
               :data-meta-option-id="concatLevel(leaf.level,index2).join('.')"
               @click="handleOptionClicked(child,leaf.level,index2)"
               :key="index2"
               v-for="(child,index2) in leaf.data">
            <slot name="child" :leaf="leaf" :child="child" :index="index2"></slot>
            <svg
                class="icon mld-option-item-expandBtn"
                :class="{'mld-option-item-expandBtn--active':
              root.find(item => levelsEqual(item.level,concatLevel(leaf.level,index2)) ),
              'hidden':!child[childPropName] || !child[childPropName].length
        }"
                @click.capture.stop="toggleRoot(child,concatLevel(leaf.level,index2))"
                t="1645423194155" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2058" width="12" height="12"><path d="M326.9 67l446.6 447.7-446.6 447.5-76.2-76.3 370.5-371.2-370.5-371.4L326.9 67z m0 0" p-id="2059"></path></svg>
          </div>
        </template>


      </div>
    </div>

    <div class="meta-level-dropDown--defaultContainer" ref="defaultDropDownContainer" :style="{'z-index':zIndex?zIndex:12}"></div>
  </div>

</template>

<script>

const LEVEL_SELECTOR_EVENTS = {
  DROPDOWN_CLOSED:0,
  DROPDOWN_OPENED:1,
  DROPDOWN_POSITION_UPDATED:2,
}
import {colorLog,singleListenerManager} from "../../util";

export default {
  name: "MetaLevelSelector",
  props:{
    selectorData:{
      type:Object,
      required:true
    },
    dropDownContainer:[String,Object],
    childPropName:{
      type:String,
      required:true
    },
    valuePropName:{
      required:true
    },
    classifyPropName:{
      required:false
    },
    multiple:{
      type:Boolean
    },
    textOverflow:{
      type:String,
      default:'ellipse'
    },
    value:{
      //注意这是给v-model使用的prop
      default:''
    },
    placeholder:{
      required:false
    },
    zIndex:{

    }
  },
  data(){
    return {
      root:[],
      inputText:"",
      options:{},//由MetaSelectorOption来补充
      optionRefs:{},
      showDropDown:false,
      selectedValue:null,
      //dropDown左上角位置
      dropDownContainerLeft:0,
      dropDownContainerTop:0,
      //位置状态
      isResetting:false,
      defaultDropDownContainer:null,
      //防抖重置位置
      debounceResetPositionFn:this.debounce(this.resetDropDownPosition,100),
      listeners:[],
      scrollRef:null,
    }
  },
  watch:{
    cradle:{
      handler(newVal){
        if(newVal[this.childPropName].length > 0){
          this.init();
        }
      },
      immediate:true

    },
    value:{
      handler(newVal){
        this.select(newVal);
      },
    }
  },
  computed:{
    cradle(){
      return {[this.childPropName]:this.selectorData};
    },
    processedRoot(){
      if(this.classifyPropName){

        let res = this.root.reduce((t,c,i)=>{
          t.push({
            data:this.classifyTargets(c.data),
            level:c.level,
            offsetY:c.offsetY,
            parent:c.parent
          });
          return t;
        },[]);

        return res
      }

      return this.root;

    }
  },

  created() {
    console.log(this.selectorData);
  },
  mounted() {
    //默认选中处理
    this.select(this.value);
    this.resetDropDownPosition();
    this.updateScrollRef();
    this.bindGlobalEvents();

    let dropDownContainer;
    if(typeof this.dropDownContainer == 'string'){
      dropDownContainer = document.querySelector(this.dropDownContainer);
    }else if(this.dropDownContainer){
      dropDownContainer = this.dropDownContainer;
    }else{
      dropDownContainer = this.$refs.defaultDropDownContainer;
      this.defaultDropDownContainer = dropDownContainer;
      document.body.appendChild(dropDownContainer);
    }

    dropDownContainer.classList.add('meta-level-selector--variation');//加上CSS变量;
    dropDownContainer.appendChild(this.$refs.dropDown);
  },
  unmounted() {
    this.offGlobalEventsListener();

    if(this.defaultDropDownContainer){
      this.defaultDropDownContainer.remove();
    }

    singleListenerManager.off(LEVEL_SELECTOR_EVENTS.DROPDOWN_OPENED);
    singleListenerManager.off(LEVEL_SELECTOR_EVENTS.DROPDOWN_CLOSED);
  },
  methods:{
    init(){
      this.createRoot(this.cradle,[0]);
    },
    bindGlobalEvents(){
      this.registerGlobalEventsListener(window,'resize',this.debounceResetPositionFn);
      this.registerGlobalEventsListener(document,'click',(e)=>{
        let flag = this.$refs.selector.contains(e.target) || this.$refs.defaultDropDownContainer.contains(e.target);
        if(!flag){
          this.closeDropDown();
        }
      });
      this.registerGlobalEventsListener(this.scrollRef,'scroll',(e)=>{
        this.closeDropDown();
      })
    },
    offGlobalEventsListener(){
      this.listeners.forEach(item=>{
        let {target,eventName,callback,otherParams} = item;
        if(otherParams && otherParams.length > 0){
          target.removeEventListener(eventName,callback,otherParams);
        }else{
          target.removeEventListener(eventName,callback);
        }
      })
    },
    registerGlobalEventsListener(target,eventName,callback,...args){
      if(args && args.length > 0 ){
        target.addEventListener(eventName,callback,...args);
      }else{
        target.addEventListener(eventName,callback);
      }
      this.listeners.push({
        target,
        eventName,
        callback,
        otherParams:args
      })

    },
    updateScrollRef(){
      let node = this.$refs.selector.parentElement;
      this.scrollRef = node;
    },
    toggleRoot(target,level){
      let exitedIndex = this.root.findIndex(item => item.level.join(',') == level.join(','));
      if(exitedIndex > -1){
        //如果存在就从数组去掉
       this.removeRootByLevel(target,level,true);
      }else{
        this.createRoot(target,level,true);
        this.$nextTick(()=>{
          //做UI位置的调整
          this.resetDropDownPosition();
          this.resetRootOffsetY();
        })
      }
    },
    toggleDropDown(){
      if(this.showDropDown){
        this.closeDropDown();
      }else{
        this.openDropDown();
      }
    },
    closeDropDown(){
      this.showDropDown = false;
    },
    openDropDown(){
      this.resetDropDownPosition();
      this.showDropDown = true;
    },
    resetDropDownPosition(){
      this.isResetting = true
      this.$nextTick(()=>{
        this.resetPositionToPreventOverflow();
        this.$nextTick(()=>{
          this.isResetting = false;
          // singleListenerManager.emit(LEVEL_SELECTOR_EVENTS.DROPDOWN_POSITION_UPDATED);
        })
      })
    },
    handleTransitionEnd(showDropDown,e){
      // colorLog.$1('handleTransitionEnd',showDropDown,e)
      if(e.propertyName == 'top'){
        singleListenerManager.emit(LEVEL_SELECTOR_EVENTS.DROPDOWN_POSITION_UPDATED);
        return;
      }

      if(showDropDown){
        singleListenerManager.emit(LEVEL_SELECTOR_EVENTS.DROPDOWN_OPENED);
      }else{
        singleListenerManager.emit(LEVEL_SELECTOR_EVENTS.DROPDOWN_CLOSED);
      }
    },
    resetPositionToPreventOverflow(){
      function toNumber(str){
        return Number(str.slice(0,-2));
      }

      let offset = 10; //纵向距离偏移量
      const right = 10; //和屏幕右边的最小距离
      const bottom = 10; //和屏幕底部的最小距离

      let selectorBar = this.$refs['selectorBar'];
      let dropDown = this.$refs['dropDown'];
      let {x:selectorX,y:selectorY,width:selectorWidth,height:selectorHeight} = selectorBar.getBoundingClientRect();
      let {x:dropDownX,y:dropDownY,width:dropDownWidth,height:dropDownHeight} = dropDown.getBoundingClientRect();

      let {top:selectorTop,left:selectorLeft} = getComputedStyle(selectorBar);
      let {top:dropDownTop,left:dropDownLeft} = getComputedStyle(dropDown);
      let deltaY;
      let overflowY = this.computeDropDownOverFlowY(selectorY + selectorHeight + offset,bottom);
      if(overflowY > 0){
        //将dropdown放在selector上面
        deltaY = dropDownY - ( selectorY - offset - dropDownHeight);
      }else{
        deltaY = dropDownY - selectorY - selectorHeight - offset;
      }

      let overflowX = this.computeDropDownOverFlowX(selectorX,right);
      let deltaX = dropDownX - selectorX + overflowX;

      this.dropDownContainerTop = toNumber(dropDownTop) - deltaY;
      this.dropDownContainerLeft = toNumber(dropDownLeft) - deltaX;

    },
    classifyTargets(targets){
      if(!this.classifyPropName){
        console.error('没有classifyPropName.无效调用');
        return;
      }

      let groupedData = targets.reduce((classifiedData,target,index)=>{
        if(index == 0) return [{ groupName:target[this.classifyPropName],children:[target] }];
        let groupAlreadyCreated = classifiedData.find(data => target[this.classifyPropName] === data.groupName);
        if(groupAlreadyCreated){
          groupAlreadyCreated.children.push(target);
        }else{
          classifiedData.push({
            groupName:target[this.classifyPropName],
            children:[target]
          })
        }

        return classifiedData;
      },[]);
      let index = 0;
      groupedData.forEach(data => {
        data.children.forEach(child => {
          child['_index'] = index;
          index++;
        })
      })
      return groupedData;
    },
    computeDropDownOverFlowX(replaceX,right = 0){
      let dropDown = this.$refs['dropDown'];
      let {x:dropDownX,y:dropDownY,width:dropDownWidth,height:dropDownHeight} = dropDown.getBoundingClientRect();
      if(!isNaN(replaceX)){
        dropDownX = replaceX;
      }

      if(dropDownX < 0){
        return dropDownX;
      }

      let overflowX = dropDownX + dropDownWidth - window.innerWidth + right;
      return overflowX > 0 ? overflowX : 0;
    },
    computeDropDownOverFlowY(replaceY,bottom){
      let dropDown = this.$refs['dropDown'];
      let {x:dropDownX,y:dropDownY,width:dropDownWidth,height:dropDownHeight} = dropDown.getBoundingClientRect();
      if(!isNaN(replaceY)){
        dropDownY = replaceY;
      }

      if(dropDownY < 0){
        return dropDownY > 0 ? dropDownY : 0;
      }
      let overflowY = dropDownY + dropDownHeight - window.innerHeight + bottom;
      return overflowY > 0 ? overflowY : 0;
    },
    levelsEqual(level1,level2){
      return level1.join('.') === level2.join('.');
    },
    concatLevel(level,index){
      return [...level,index];
    },
    createRoot(target,level,prune = true){
      let someLevelNodeIndex = this.root.findIndex(item => this.levelsEqual(item.level,level));

      if(someLevelNodeIndex == -1){
        this.root.push({data:target[this.childPropName],level:level,offsetY:0,parent:target});
      }else{
        this.root.splice(someLevelNodeIndex,1,{data:target[this.childPropName],level:level,offsetY:0,parent:target});
      }

      if(prune){
        //接下来清理和它level"不一致"的对象
        this.pruneRoot(level);
      }
    },
    resetRootOffsetY(){
      let proxy = [];
      let pros = Array(this.root.length - 1).fill(null).map( (_,index) => new Promise(res => proxy[index] = res));

      this.root.forEach(async (info,index) => {
        if(index != 0){
          await pros[index - 1];
        }
        let level = info.level;
        let optionRef = this.getOptionRefByLevel(level);
        let offsetY = 0;
        if(optionRef){
          let optionItem = optionRef.$el.closest('.mld-option-item');
          let parent = optionItem.closest('.meta-level-dropDown');
          let {y:optionItemY} = optionItem.getBoundingClientRect();
          let {y:parentY} = parent.getBoundingClientRect();
          //padding偏移量5 border偏移量1
          offsetY = optionItemY - parentY - 5 - 1;
        }
        info.offsetY = offsetY;
        this.$nextTick(proxy[index]);
      })
    },
    removeRootByLevel(target,level,prune = true){
      let exitedIndex = this.root.findIndex(item => this.levelsEqual(item.level,level));
      this.root.splice(exitedIndex,1);
      if(prune){
        //接下来清理和它level"不一致"的对象
        this.pruneRoot(level);
      }

      this.debounceResetPositionFn();
    },
    //"修剪"root
    pruneRoot(newLevel){

      let levelLen = newLevel.length;
      let previousLevel = newLevel.slice(0,-1);
      this.root = this.root.filter(item => {
        if( this.levelsEqual(newLevel,item.level)){
          return true;
        }
        let allSame = false;
        //查找其他的对象的level是否包含新对象的level的previousLevel字符串
        for(let i = 0,len = item.level.length,level = item.level; i < len ; i++){
          if(level[i] == previousLevel[i]){
            allSame = true;
          }else{
            allSame = false;
            break;
          }
        }
        return item.level.length < levelLen && allSame;
      })
    },
    handleOptionClicked(target,level,index){
      let newLevel = this.concatLevel(level,index);
      this.$emit('option-clicked',target,this.optionRefs[newLevel],newLevel);
    },
    handleSelectorClicked(){
      this.toggleDropDown();
    },
    select(value){
      if(value === this.selectedValue){
        return;
      }
      console.log(`Meta-level-selector, selected `,value)
      let label = this.options[value];
      if(label){
        this.inputText = label;
        this.selectedValue = value;

        this.pruneRoot(this.getLevelByValue(value).slice(0,-1));

        this.$emit('update:value',value); //注意,这是v-model的事件
        this.$emit('selected', {value,target:this.getTargetByValue(value),selector:this});
      }else{
        //发现在现有已渲染的option里头找不到,那就全部遍历出来(display要设none)挨个去匹配
        let res = this.matchValue(value,[0]);
        if(res.found){
          for(let i = 1,len = res.level.length - 1;i< len;i++){
            let currentLevel = res.level.slice(0,i + 1);
            let target = this.getTargetByLevel(currentLevel);
            this.createRoot(target,currentLevel,true);
          }
        }

        singleListenerManager.on(LEVEL_SELECTOR_EVENTS.DROPDOWN_OPENED,()=>{
          this.resetRootOffsetY();
        },true);
      }
    },
    getTargetByValue(value){
      let find = (obj) => {
       if(obj[this.valuePropName] == value){
         return obj;
       }else if(obj[this.childPropName]){
         for(let child of obj[this.childPropName]){
           let target = find(child);
           if(target){
            return target;
           }
         }
         return;
       }else{
         return;
       }
      }

      const target = find(this.cradle);
      return target;
    },
    getLevelByValue(val){
      let index = -1;
      let parentLevel = this.root.find(item=>{
        let flag = false;
        item.data.forEach((option,_index)=>{
          if(option[this.valuePropName] === val){
            index = _index;
            flag = true;
          }
        })
        return flag;
      }).level;
      return this.concatLevel(parentLevel,index);
    },
    getTargetByLevel(level){
      if(level.length == 1){
        return this.cradle;
      }

      let target = this.cradle;
      for(let i = 1,len = level.length;i < len ; i++){
        target = target[this.childPropName][level[i]];
      }
      return target;
    },
    getTargetsByLevel(level){
      let targets = [this.cradle];
      if(level.length == 1){
        return targets;
      }

      let target = this.cradle;
      for(let i = 1,len = level.length;i < len ; i++){
        target = target[this.childPropName][level[i]];
        targets.push(target);
      }
      return targets;
    },
    matchValue(value,fromLevel){
      let found = false;
      let target = null;
      let that = this;
      let level = fromLevel;

      function find(item,value,prevLevel){
        let alteredLevel = prevLevel;
        let children = item[that.childPropName];
        if(!children || children.length == 0){
          return;
        }

        children.forEach((it,index)=>{
          if(found){
            return;
          }

          if(it[that.valuePropName] == value){
            alteredLevel.push(index);
            found = true;
            target = it;
          }else{
           let res = find(it,value,[...alteredLevel,index]);
            if(found){
              alteredLevel = res;
            }
          }
        })

        return alteredLevel;
      }

      let fromTarget = this.getTargetByLevel(fromLevel);
      level = find(fromTarget,value,level);

      return {found,target,level};
    },
    setOption(value,label){
      if(this.options[value]){
        return;
      }
      this.options[value] = label;
    },
    deleteOption(value){
      delete this.options[value];
    },
    getLabelByValue(value){
      return this.options[value];
    },
    getOptionRefByValue(value){
      return this.optionRefs.find(ref => ref.temp_value === value);
    },
    getOptionRefByLevel(level){
      return this.optionRefs[level.join('.')];
    },

    //与option组件依赖的方法
    optionClickedListener({label,value,optionId:level}){
      // let targetObj = this.getTargetByLevel(level.split('.'));
      this.select(value);
    },
    optionMountedListener({label,value,optionId}){

    },
    registerOptionComponent(optionLevel,component){
      this.optionRefs[optionLevel] = component;
    },
    offOptionComponent(optionLevel){
      delete this.optionRefs[optionLevel];
    },
    debounce(fn,wait) {
      let timer = null;
      let context = this;
      return function (...args){
        if(timer){
          clearTimeout(timer);
        }

        timer = setTimeout(()=>{
          fn.apply(context,args);
          timer = null;
        },wait);
      }
    },
  }
}
</script>
<style>
.meta-level-selector--variation{
  --selector-lightBlue:var(--lightBlue,#5280FFFF);
  --selector-hoverLightBlue: var(--hoverLightBlue,#ECF5FFFF);
  --selector-gray: #dedbdb;
  --selector-gray2: #aaa;
  --selector-border-radius:3px;
  --selector-background-color:#fff;
  --selector-topBar-zIndex:5;

  --mld-options-padding:5px;
  --meta-level-dropDown-left:0px;
  --meta-level-dropDown-top:var(--mld-options-padding);
}

</style>
<style scoped>
.meta-level-dropDown--defaultContainer{
  position: absolute;
  width: 0;
  height: 0;
  overflow: visible;
  top:0;
  z-index: 12;
}

.meta-level-selector{
  position: relative;
}

.meta-level-selector-bar{
  width: 100%;
  background-color: var(--selector-background-color);
  border:1px solid var(--selector-gray);
  border-radius: var(--selector-border-radius);
  padding: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-sizing: border-box;
}
.selector-bulletin{
  flex-grow: 1;
  flex-shrink: 1;
  text-align: left;
  padding-left: 3px;
  font-weight: bold;
}
.selector-bulletin-textOverflow--ellipse{
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.selector-bulletin-textOverflow--placeholder{
  font-weight: normal !important;
  color:var(--selector-gray2);
}
.hidden{
  display: none;
}
.hidden-block{
  visibility: hidden;
}
.meta-level-dropDown{
  transform-origin: top;
  position: absolute;
  white-space: nowrap;
  width: 0;
  transition:.15s cubic-bezier(0, 0.8, 0.26, 0.95);
  top: var(--meta-level-dropDown-top);
  left: var(--meta-level-dropDown-left);
  z-index: calc(var(--selector-topBar-zIndex) - 1);
}
.meta-level-selector-bar .icon{
  transition: .2s all;
  fill: var(--selector-gray);
}
.meta-level-selector-bar--active{
  border-color: var(--selector-lightBlue);
}
.meta-level-selector-bar--active .icon{
  transform: rotate(90deg);
  fill:var(--selector-lightBlue);
}
.meta-level-dropDown--hidden{
  opacity: 0;
  transform: scaleY(0);
}
.meta-level-dropDown--resetting{
  width:auto;
}
.mld-option-group-name{
  margin: 5px 0;
  text-align: left;
  font-weight:bold;
  color:#3c4146;
  user-select: none;
}
.mld-option-group:not(:first-child){
  margin-top: 5px;
}
.mld-option-group:first-child .mld-option-group-name{
  margin-top: 0;
}
.mld-option-group:not(:first-child){
  border-top: 1px solid #eee;
}
.mld-options{
  display:inline-block;
  border:1px solid var(--selector-gray);
  border-radius:var(--selector-border-radius);
  padding: var(--mld-options-padding);
  align-items: center;
  box-shadow: 0 0 3px 0px #ccc;
  background-color: var(--selector-background-color);
  vertical-align: top;
  max-height: 260px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-gutter: stable;
}
.mld-options::-webkit-scrollbar-track-piece {
  background-color: #f8f8f8;
}

.mld-options::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  display: block;
}

.mld-options::-webkit-scrollbar-thumb {
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 5px;
}

.mld-options::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}


.mld-options:not(:first-child){
  margin-left: 5px;
}
.mld-options-beginning{
  position:relative;
}
.mld-options-beginning::before{
  position: absolute;
  content:'';
  background-color: var(--selector-background-color);
  width: calc(1.4 * var(--mld-options-padding));
  height: calc(1.4 * var(--mld-options-padding));
  border: 1px solid var(--selector-gray);
  border-bottom-color:transparent;
  border-right-color: transparent;
  transform: rotate(45deg);
  left: 20%;
  top: calc(-1 * var(--mld-options-padding));
}
.mld-option-item{
  min-width: 100px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.mld-option-item:hover{
  background-color:var(--selector-hoverLightBlue);
}
.mld-option-item >>> >div[class*=option]:first-child{
  flex-grow: 1;
}
.mld-option-item-expandBtn{
  cursor: pointer;
  user-select: none;
  fill:var(--selector-gray);
  padding-left: 5px;
}
.mld-option-item-expandBtn--active{
  fill:var(--selector-lightBlue);
}
</style>
